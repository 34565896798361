<template>
  <section class="detail-wrap">
    <section v-loading.fullscreen.lock="fullscreenLoading">
      <div class="container">
        <div class="artHeader">
          <span class="title">{{ article.title }}</span>
          <div class="btnWrapper">
            <span class="mingan" id="emotion">{{ article.emotion ===0? '正面' :article.emotion ===1? '负面' : '敏感' }}</span>
            <span class="operateBtn" @click="operate">操作
              <div class="cancle" @click.stop="setUntrack">取消追踪</div>
            </span>
          </div>
          <a class="sourceURL" :href="article.url" target="_blank">查看原文&gt;</a>
        </div>
        <div class="nums">
          <span class="createDate">{{ formatDate(article.publishDate) }}</span>
          <span class="source">{{ article.sourceName }}</span>
          <span>点赞量（<span class="browseNum">{{ article.goodNum }}</span>）</span>
          <span>评论量（<span class="commentNum">{{ article.commentNum }}</span>）</span>
          <span>转发量（<span class="forwardNum">{{ article.transpondNum }}</span>）</span>
        </div>
        <div style="position: relative;">
          <div class="nodata">暂无数据</div>
        </div>
        <div id="echarts" style="width: 100%;height:210px"></div>
        <div class="abstract">
          <span>摘要：</span>
          <p class="summarymsg">{{ article.summary }}</p>
        </div>
        <div class="summary">
          <span>总结：</span>
          <p class="msg">截至分析时间，  “ {{ article.title }}”   此新闻共收获点赞数{{ article.goodNum }}条、 评论数{{ article.commentNum }}条，转发数{{ article.transpondNum }}个</p>
        </div>
      </div>
      <div class="survey">
      	<h3>新闻概况</h3>
      	<ul>
      		<li class="items">
      			<span><i class="fa fa-share-square-o"></i></span>
      			<div class="record">
      				<span>转发量</span>
      				<span class="times" id="forwoard_times">{{ article.transpondNum }}次</span>
      				<p>开启追踪后的转发总数</p>
      			</div>
      		</li>
      		<li class="items">
      			<span><i class="fa fa-edit"></i></span>
      			<div class="record">
      				<span>评论量</span>
      				<span class="times" id="comment_times">{{article.commentNum}}次</span>
      				<p>开启追踪后的评论总数</p>
      			</div>
      		</li>
      		<li class="items">
      			<span><i class="fa fa-hand-pointer-o"></i></span>
      			<div class="record">
      				<span>点赞量</span>
      				<span class="times" id="read_times">{{ article.goodNum }}次</span>
      				<p>开启追踪后的总点击量</p>
      			</div>
      		</li>
      	</ul>
      </div>
    </section>
  </section>
</template>

<script>
  import { getDetail, setUntrack } from '@/api/dataTrack'
  import $ from 'jquery'
  export default{
    data(){
      return {
        list: {},
        article: {},
        snList: [],
        fullscreenLoading: false
      }
    },
    mounted() {
      this.getDetailData()
    },
    methods:{
      formatDate (time) {
        if (time && typeof (time) === 'string') {
          time = time.replace(/-/g, '/').substr(0, time.indexOf('.'))
        }
        let date = new Date(time)
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        let hour = date.getHours()
        let min = date.getMinutes()
        let second = date.getSeconds()
        if (month < 10) {
          month = '0' + month
        }
        if (day < 10) {
          day = '0' + day
        }
        if (hour < 10) {
          hour = '0' + hour
        }
        if (min < 10) {
          min = '0' + min
        }
        if (second < 10) {
          second = '0' + second
        }
        return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + second
      },
      async getDetailData () {
        this.fullscreenLoading = true
        var sn = this.$route.params.sn
        const res = await getDetail({sn:sn})
        console.log(res)
        if(res.code === 0){
          this.article = res.data.body
          this.list = res.data.list
          this.lineChart()
        }

        this.fullscreenLoading = false
      },
      operate(){
        $('.cancle').show()
      },
      async setUntrack(){
        this.snList.push(this.article.sn)
        const res = await setUntrack("list="+this.snList)
        if(res.code === 0){
          this.$message(res.msg)
        }
      },
      lineChart () {
        this.tu1 = this.$echarts.init(document.getElementById('echarts'))
        var option = {
        	tooltip: {
        		trigger: 'axis',
        		axisPointer: {
        			type: 'cross',
        			label: {
        				backgroundColor: '#6a7985'
        			}
        		}
        	},
        	legend: {
        		data: ['点赞量', '评论量', '转发量']
        	},
        	grid: {
        		left: '3%',
        		right: '4%',
        		bottom: '3%',
        		containLabel: true
        	},
        	xAxis: this.list.xAxis,
        	yAxis: [
        		{
        			type: 'value'
        		}
        	],
        	series: this.list.series
        };

       this.tu1.setOption(option)
      }
    }
  }
</script>

<style lang="scss">
  .detail-wrap {
    width: 100%;
    height: 100%;

    .container {
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 5px 5px 5px #888888;
      padding: 15px;

      .title {
        display: inline-block;
        font-size: 20px;
        width: 86%;
      }

      .artHeader {
        margin: 10px;
      }

      .btnWrapper {
        float: right;
        margin: 0 10px;
      }

      .mingan {
        color: #FF6600;
        border: 1px solid #FF6600;
        padding: 3px 5px;
        border-radius: 5px;
      }

      .fumian {
        color: red;
        border: 1px solid red;
        padding: 3px 5px;
        border-radius: 5px;
      }

      .zhengmian {
        color: #409EFF;
        border: 1px solid #409EFF;
        padding: 3px 5px;
        border-radius: 5px;
      }

      .operateBtn {
        color: #409EFF;
        font-size: 16px;
        margin-left: 10px;
        cursor: pointer;
      }

      .operateBtn .cancle {
        background-color: #fff;
        box-shadow: 0 0 2px rgba(8, 8, 8, .3);
        margin-top: 8px;
        text-align: center;
        display: none;
        color: #333;
        line-height: 30px;
        font-size: 14px;
      }
      .sourceURL{
        display: block;
        color: #666;
        margin-top: 8px;
        font-size: 16px;
      }
      .nums {
        font-size: 17px;
        color: #999999;
        margin: 10px;
        .createDate,.source {
          margin-right: 15px;
        }
      }

      #echarts {
        margin-top: 20px;
      }

      .abstract,
      .summary {
        position: relative;
        font-size: 16px;
        line-height: 26px;
        margin: 10px;
        span {
          position: absolute;
          left: 0;
        }
      }
      .summarymsg,.msg {
        text-indent: 48px;
      }
    }

    .survey {
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 5px 5px 5px #888888;
      padding: 15px;
      margin: 15px 0;

      h3 {
        font-size: 18px;
        margin-left: 10px;
      }

      .items {
        background-color: rgba(215, 215, 215, .2);
        width: 46%;
        display: inline-block;
        margin: 10px 0 0 15px;
        padding: 15px 0 15px 20px;
        box-sizing: border-box;
      }

      .fa {
        font-size: 40px;
        color: #333;
        vertical-align: super;
      }

      .record {
        display: inline-block;
        font-size: 16px;
        margin-left: 20px;
        line-height: 25px;
      }

      .record .times {
        font-size: 20px;
        color: #CC0000;
      }
    }
  }

  .nodata {
    position: absolute;
    top: 100px;
    left: 50%;
    margin-left: -35px;
    display: none;
  }
</style>
